import React, { Component } from 'react'

const Hero = class extends Component {
  constructor(props) {
    super(props)

    this.createArrow = this.createArrow.bind(this);
    this.randomBounceArrow = this.randomBounceArrow.bind(this);
    this.doScrolling = this.doScrolling.bind(this);
    this.getElementY = this.getElementY.bind(this);    
  }

  componentDidMount() {
    const heroBanner = document.getElementById(this.props.heroId);
    const nextSection = document.getElementById(this.props.nextId);
    const scrollDownArrow = this.createArrow();

    scrollDownArrow.addEventListener('click', this.doScrolling.bind(null, nextSection, 1500));
    scrollDownArrow.addEventListener('animationend', () => {
      scrollDownArrow.classList.remove('bounce')
    
      this.randomBounceArrow(scrollDownArrow, 5000, 12000)
    });
    
    // Append to element
    heroBanner.append(this.randomBounceArrow(scrollDownArrow, 5000, 12000))
  }

  getElementY(query) {    
    if (typeof query === "object")
      return window.pageYOffset + query.getBoundingClientRect().top
    else
      return window.pageYOffset + document.querySelector(query).getBoundingClientRect().top
  }

  doScrolling(element, duration) {
    var startingY = window.pageYOffset
    var elementY = this.getElementY(element)
    // If element is close to page's bottom then window will scroll only to some position above the element.
    var targetY = document.body.scrollHeight - elementY < window.innerHeight ? document.body.scrollHeight - window.innerHeight : elementY
    var diff = targetY - startingY
    // Easing function: easeInOutCubic
    // From: https://gist.github.com/gre/1650294
    var easing = function (t) { return t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1 }
    var start
  
    if (!diff) return
  
    // Bootstrap our animation - it will get called right before next frame shall be rendered.
    window.requestAnimationFrame(function step(timestamp) {
      if (!start) start = timestamp
      // Elapsed miliseconds since start of scrolling.
      var time = timestamp - start
      // Get percent of completion in range [0, 1].
      var percent = Math.min(time / duration, 1)
      // Apply the easing.
      // It can cause bad-looking slow frames in browser performance tool, so be careful.
      percent = easing(percent)
  
      window.scrollTo(0, startingY + diff * percent)
  
      // Proceed with animation as long as we wanted it to.
      if (time < duration) {
        window.requestAnimationFrame(step)
      }
    })
  }

  createArrow() {
    var arrowButton = document.createElement('button');
  
    arrowButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.6 18.32"><path d="M15.78,12.56l-3-3L9.11,5.9,5.94,2.73c-.51-.52-1-1-1.54-1.54l0,0A2.35,2.35,0,0,0,2.77.5a2.3,2.3,0,0,0-1.6.67A2.26,2.26,0,0,0,.5,2.77a2.35,2.35,0,0,0,.67,1.61l1.29,1.3,3.11,3.1,3.71,3.71,3.14,3.14c.46.46.91.94,1.38,1.39a2.89,2.89,0,0,0,4,0l.12-.12.72-.72,2.82-2.82,3.68-3.68,3.35-3.36,1.86-1.85.08-.09a2.35,2.35,0,0,0,.67-1.61,2.3,2.3,0,0,0-.67-1.6A2.26,2.26,0,0,0,28.82.5a2.34,2.34,0,0,0-1.6.67h0L26,2.43l-3,3L19.27,9.11C18.22,10.16,16.83,11.51,15.78,12.56Z"/></svg>';
    arrowButton.setAttribute('title', 'Click here or scroll down to view more');  
    arrowButton.setAttribute('class', 'more');
  
    return arrowButton
  }

  randomBounceArrow(element, minTime, maxTime) {
    setTimeout(() => {
      element.classList.add('bounce');
    }, Math.floor((Math.random() * maxTime) + minTime));
  
    return element;
  }

  render() {    
    const { children } = this.props
    
    return (
      <div className="hero" style={{ backgroundImage: `url(/static/images/${this.props.heroImage}.jpg)` }} id={this.props.heroId}>
        <header
          data-sal-duration="600"
          data-sal="fade"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        >
          { children }
        </header>
      </div>
    )
  }
}

export default Hero
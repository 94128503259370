import React, { Component } from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import Hero from '../components/hero-image'

const AboutUs = class extends Component {
  constructor(props) {
    super(props)

    this.stepClickHandler = this.stepClickHandler.bind(this)
    this.timeoutId = 0
    this.isRunning = false
  }

  componentDidMount = () => {
    if (this.ComponentCanRun()) {
      this.setHeight()
      this.stepChange(0, 8000)
      this.isRunning = true;
    }

    window.addEventListener('resize', this.setHeight)
  }

  ComponentCanRun = () => {
    const isNotMobile = window.matchMedia('(min-width: 720px)').matches

    return isNotMobile
  }

  setHeight = () => {
    const 
        process = document.querySelector('div.process')
      , processes = Array.prototype.slice.call(process.childNodes)
    let height = 0;

    if (this.ComponentCanRun()) {    
      processes.forEach((p) => {
        if (p.firstChild.clientHeight > height) {
          height = p.firstChild.clientHeight 
        }
      })

      process.style.height = `${height}px`

      if (!this.isRunning) {
        this.stepChange(0, 8000)
      }
    } else {
      clearTimeout(this.timeoutId)
      process.removeAttribute('style')
      this.isRunning = false;
    }
  }

  stepChange = (stepId, delay) => {    
    clearTimeout(this.timeoutId)

    const 
        steps = document.querySelectorAll('.steps li')
      , process = document.querySelector('div.process')
      , processes = Array.prototype.slice.call(process.children)
      , activeStep = process.querySelector('div.active')
      , activeLink = document.querySelector('.steps .active')

    if (activeStep) {
      activeStep.classList.remove('active')
      activeLink.classList.remove('active')
    }

    processes[stepId].classList.add('active')
    steps[stepId].firstChild.classList.add('active')
    // TODO::Move cursor    

    let nextStepId = (stepId +1 >= processes.length ? 0 : stepId +1)
    this.timeoutId = setTimeout(this.stepChange.bind(null, nextStepId, 8000), delay)
  }

  stepClickHandler = (event) => {
    event.preventDefault()
    clearTimeout(this.timeoutId)

    // Determine child and call stepChange
    const stepId = Array.prototype.indexOf.call(event.target.parentNode.parentNode.children, event.target.parentNode)                
    this.stepChange(stepId, 16000)
  }

  render() {
    return (  
      <Layout>
        <Helmet title="About | Rudd Joinery">
          <meta name="description" content="About Rudd Joinery, our accreditations, our process and our story" />
        </Helmet>
        <Hero heroImage="about-hero" heroId="about-hero" nextId="about">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 68.29 68.29"
              className="rudd-joinery"
            >
              <path d="M34.14,2.53A31.62,31.62,0,1,0,65.76,34.14,31.61,31.61,0,0,0,34.14,2.53Zm-4,41c0,8-3.37,12.76-8.76,12.76l-.58-1.16c2.6-.38,5.11-4.19,5.11-9.1V20.8c0-2.06-.41-2.54-4-3.23v-1.2h8.18Zm20.13,4.91h-7l-.19-.14a34.11,34.11,0,0,0-3-5.3l-6-8.81h-2V32.35H34.2c2.89,0,6.21-2.31,6.21-7,0-3.66-2.07-7.12-8-7.12h-.28V16.37h1c8.67,0,11.94,3.56,11.94,7.75,0,4.53-4,7.61-6.36,8.81v.63l6.94,10.15c2.07,3,3,3.08,4.28,3.18l.53,0Z" />
              <path d="M34.14,0A34.15,34.15,0,1,0,68.29,34.14,34.15,34.15,0,0,0,34.14,0Zm0,65.76A31.62,31.62,0,1,1,65.76,34.14,31.61,31.61,0,0,1,34.14,65.76Z" />
              <path d="M45.66,43.71,38.72,33.56v-.63c2.31-1.2,6.36-4.28,6.36-8.81,0-4.19-3.27-7.75-11.94-7.75h-1v1.88h.28c5.92,0,8,3.46,8,7.12,0,4.67-3.32,7-6.21,7H32.14v1.88h2l6,8.81a34.11,34.11,0,0,1,3,5.3l.19.14h7l.19-1.54-.53,0C48.69,46.79,47.73,46.7,45.66,43.71Z" />
              <path d="M22,17.57c3.54.69,3.92,1.17,4,3.23V46.07c0,4.91-2.51,8.72-5.11,9.1l.58,1.16c5.39,0,8.76-4.72,8.76-12.76V16.37H22Z" />
            </svg>
          <h1>About us</h1>
        </Hero>
        <div className="section" id="about">
          <div className="column-block story">
            <div>
              <h2>Accreditations</h2>
              <p>
                We’re proud to be part of{' '}
                <a href="https://www.bwf.org.uk" rel="external">
                  The British Woodworking Federation
                </a>{' '}
                (BWF), an organisation that offers its members advice, business
                support and wood industry information. They provide a voice for the
                wood industry in the UK and influence standards, regulations and
                policies within the industry.
              </p>
            </div>
            <div>
              <h2>CE Marking</h2>
              <p>
                In July 2013 it become a mandatory requirement to CE mark external
                timber windows and doors, and all our external products meet these
                standards.
              </p>
            </div>
            <div>
              <h2>Showroom</h2>
              <p>
                Visit our dedicated showroom where you can view our product range as
                we have a variety of windows and doors on display in a range of
                styles and finishes. See the quality and craftsmanship for yourself
                and speak to our experienced team about your requirements in a
                relaxed environment over a cup of coffee.
              </p>
            </div>
          </div>
        </div>
        <a href="/contact-us" className="cta-bar">
          <div>
            <strong>To receive a no obligations quote click here</strong>
          </div>
        </a>
        <div className="section">
          <div className="wrapper">
            <div className="story text-block">
              <h2>The journey</h2>
              <p>
                Follow our step by step guide and find out how Rudd Joinery can work
                with you on supplying high quality bespoke performance joinery
                tailoured to suit your project
              </p>
            </div>
            <div className="the-journey">
              <div className="steps">
                <ul>
                  <li>
                    <a href="#enquiry" onClick={this.stepClickHandler}>Your enquiry</a>
                  </li>
                  <li>
                    <a href="#proposal" onClick={this.stepClickHandler}>Your proposal</a>
                  </li>
                  <li>
                    <a href="#survey" onClick={this.stepClickHandler}>Your survey</a>
                  </li>
                  <li>
                    <a href="#manufacture" onClick={this.stepClickHandler}>Your manufacture</a>
                  </li>
                  <li>
                    <a href="#delivery" onClick={this.stepClickHandler}>Your delivery</a>
                  </li>
                  <li>
                    <a href="#aftercare" onClick={this.stepClickHandler}>Your aftercare</a>
                  </li>
                </ul>
              </div>
              <div className="story process">
                <div id="enquiry">
                  <div>
                    <h3>Your enquiry</h3>
                    <p>
                      When you first get in touch, whether by telephone, email or
                      post, our dedicated and experienced sales team will guide you
                      through choosing and purchasing your product. We also welcome
                      customers at our showroom on Tattersett Business Park near
                      Fakenham in Norfolk, where you can see some of our products for
                      yourself and talk to our friendly members of staff.
                    </p>
                    <p>
                      We'll happily show you around our manufacturing facility where
                      you can see products at various different stages of production.
                      You can even arrange for one of our team to visit you on site to
                      discuss your project at a time that suits you.
                    </p>
                  </div>
                </div>
                <div id="proposal">
                  <div>
                    <h3>Your proposal</h3>
                    <p>
                      Your proposal will include a detailed specification, breakdown
                      of costs and enhanced drawings using the latest estimating
                      software. As bespoke joinery craftsmen we're able to accommodate
                      most specifications so alternative options are available.
                    </p>
                    <p>
                      Visiting our showroom can help you understand the quality of our
                      products, the manufacturing process and the range of options
                      available.
                    </p>
                  </div>
                </div>
                <div id="survey">
                  <div>
                    <h3>Your survey</h3>
                    <p>
                      Once you've placed your order with us, if required, one of our
                      surveyors will get in touch to arrange a time that suits you for
                      a survey. During the survey they will take accurate measurementa
                      to finalise your specification which then be used to produce a
                      detailed set of working drawings for you to approve and sign
                      off. Lead times begin from sign off.
                    </p>
                  </div>
                </div>
                <div id="manufacture">
                  <div>
                    <h3>Your manufacture</h3>
                    <p>
                      Our talented team in our manufacturing facility will receive the
                      final specification and working drawings from which to create
                      your bespoke products. By combining modern machinery, tooling
                      and software together with the quality of our craftsmanship
                      skills honed over many years, we'll produce your product to the
                      highest specification.
                    </p>
                    <p>
                      Once your product is checked by quality control it will be
                      carefully labelled and wrapped ready for delivery or collection.
                    </p>
                  </div>
                </div>
                <div id="delivery">
                  <div>
                    <h3>Your delivery</h3>
                    <p>
                      As soon as your product is finished, a member of our team will
                      get in touch to arrange a delivery at a convenient time. If we
                      are carrying out the installation then our expert in-house
                      installers will be in touch to arrange a mutually convenient
                      installation date.
                    </p>
                  </div>
                </div>
                <div id="aftercare">
                  <div>
                    <h3>Your aftercare</h3>
                    <p>
                      Occasionally products require slight adjustments or future
                      maintenance. Get in touch with the team to discuss any concerns
                      or further requirements and make sure you retain your infomation
                      pack as this will outline what's included in your product
                      guarantees.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hero-bar">
          <div style={{ backgroundImage: 'url(/static/images/about-hero-bar-02.jpg)' }} />
        </div>
        <div className="section rjlogo">
          <div className="wrapper">
            <div className="story text-block">
              <div>
                <h2 className="heading glyph rj">Our history</h2>
                <h3>The beginnings</h3>
                <p>
                  In the earliest form, the business was established in the early
                  1980s by local carpenter, Martin Rudd. Martin worked for private
                  customers along the north-west Norfolk coast until his son Jamie
                  followed in his dad's footsteps and began an apprenticeship in
                  1990 after gaining experience with local building and joinery
                  firms. This led to a change of direction for the business, from
                  carpentry and construction to the manufacture of quality joinery.
                </p>
                <h3>The millennium</h3>
                <p>
                  The turn of the millennium saw Martin and Jamie form{' '}
                  <strong>Rudd Joinery</strong>, with the aim of supplying
                  high-quality purpose-made joinery to private clients as well as
                  the trade. 2004 saw the move to a bigger manufacturing facility on
                  Tattersett Business Park in Fakenham, Norfolk.
                </p>
                <h3>The present</h3>
                <p>
                  Since then Rudd Joinery has seen continued success and have made
                  significant investments in the manufacturing processes including
                  machinery, tooling and software as well as investments in the
                  additional skilled craftsmen they now employ. This has allowed
                  Rudd Joinery to grow and with the modern machinery and cutting-
                  edge techniques to produce the superior quality joinery their
                  customers demand.
                </p>
                <p>
                  Almost 30 years on, the business still centres on a genuine
                  passion for joinery and for bringing customers bespoke and
                  beautiful pieces. They remain a family run business and still
                  uphold the values of offering quality workmanship at affordable
                  prices.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="cta-bar">
          <div>
            <h3>Work for us</h3>
            <p>
              We’re always on the lookout for people to join our talented team at
              Rudd Joinery. If you’re a skilled craftsman experienced in producing
              high-quality windows, doors or staircases then we’d like to hear from
              you. Please email your CV to{' '}
              <a href="mailto:careers@ruddjoinery.co.uk">
                careers@ruddjoinery.co.uk
              </a>
              .
            </p>
          </div>
        </div>
        <div className="section lightgrey eco-friendly">
          <div className="wrapper">
            <div
              className="text-block story"
              data-sal-duration="600"
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease-out-bounce"
            >
              <h2 className="heading ico eco">Eco-friendly</h2>
              <p>
                We only use wood sourced from managed sustainable forests and from
                the UK wherever possible. Many of our products are made from <a href="https://www.accoya.com">Accoya&reg;</a>
                timber, a naturally renewable and award-winning brand that reduces
                environmental impact and sourced from FSC&reg; woods.
              </p>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default AboutUs
